import React, { FC, useState, useEffect, useRef } from "react";
import emailjs from "emailjs-com";

import axios from "axios";

import {
  Button,
  Checkbox,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  Select,
  Spinner,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
  value: string;
}

export enum RoomsEnum {
  n1 = "Стандартный одноместный",
  n2 = "Стандартный двухместный",
  nV = "Номер ЛЮКС",
}

export const RequestModal: FC<IProps> = ({ isOpen, onClose, value }) => {
  const [isSmallerThan900] = useMediaQuery("(max-width: 900px)");
  const [room, setRoom] = useState<string>(RoomsEnum.n1);
  const [name, setName] = useState("");
  const [count, setCount] = useState<string | number>("");
  const [phone, setPhone] = useState<string | number>("");
  const [dateIn, setDateIn] = useState("");
  const [dateOut, setDateOut] = useState("");
  const [email, setEmail] = useState<string>("");
  const [agreement, setAgreement] = useState(false);
  const form = useRef<any>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (value) {
      setRoom(value);
    }
  }, [value]);

  const sendEmailHandler = async () => {
    try {
      const config = {
        headers: {
          "Content-Type": " multipart/form-data",
        },
      };

      let formData = new FormData();
      formData.append("text", "Hello mail");

      const response = await fetch("../../../public/sendmail.php", {
        method: "POST",
        body: formData,
      });

      if (response.ok) {
        let result = await response.json();
        alert(result.message);
      }

      console.log(response);
    } catch (error) {
      console.log("emailError>>>", error);
    }
  };

  const sendEmail = async (e: any) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const res = await emailjs.sendForm(
        "service_0qdc35e",
        "template_lalxyjp",
        form.current,
        "aG4GKN9u6dCQh3tTP"
      );
      onClose();
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    // .then(
    //   (result) => {
    //     console.log(result.text);
    //     onClose();
    //   },
    //   (error) => {
    //     console.log(error.text);
    //   }
    // );
  };

  return (
    <>
      <Modal
        isOpen={isOpen}
        onClose={onClose}
        closeOnOverlayClick={false}
        size="4xl"
        isCentered
        motionPreset="slideInRight"
        // scrollBehavior="inside"
      >
        <ModalOverlay backdropFilter="blur(8px)" />

        <ModalContent border="1px solid #282828" color="#282828" p={[4, 4, 8]}>
          <ModalHeader p={[0, 0, 4]}>
            <Text my={[0, 0, 2]} fontSize={["md", "md", "xl"]}>
              Бронь номера
            </Text>
            <Text my={[0, 0, 2]} fontSize={["xl", "xl", "3xl"]}>
              Забронировать номер
            </Text>
            <Text my={[0, 0, 2]} fontSize={["md", "md", "xl"]}>
              Мы свяжемся с вами для уточнения деталей заказа
            </Text>
          </ModalHeader>
          <ModalCloseButton size="lg" />

          <ModalBody p={[0, 0, 4]}>
            <form ref={form} onSubmit={sendEmail}>
              <Flex
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                my={[2, 2, 4]}
              >
                <Select
                  value={room}
                  onChange={(e) => setRoom(e.target.value)}
                  width={["100%", "100%", "47%"]}
                  height={["50px", "50px", "60px"]}
                  borderRadius="0px"
                  border="1px solid #282828"
                  my={[4, 4, 0]}
                  name="type"
                >
                  {Object.keys(RoomsEnum).map((el) => (
                    <option
                      key={Object(RoomsEnum)[el]}
                      value={Object(RoomsEnum)[el]}
                    >
                      {Object(RoomsEnum)[el]}
                    </option>
                  ))}
                  {/* <option value="Стандартный одноместный">
                    Стандартный одноместный
                  </option>
                  <option value="Стандартный двухместный">
                    Стандартный двухместный
                  </option>
                  <option value="Номер ЛЮКС">Номер ЛЮКС</option> */}
                </Select>

                <Input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  width={["100%", "100%", "47%"]}
                  height={["50px", "50px", "60px"]}
                  placeholder="ФИО"
                  type="text"
                  borderRadius="0px"
                  border="1px solid #282828"
                  name="name"
                />
              </Flex>

              <Flex
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                my={[2, 2, 4]}
              >
                <Input
                  value={count}
                  onChange={(e) => setCount(e.target.value)}
                  width={["100%", "100%", "47%"]}
                  height={["50px", "50px", "60px"]}
                  my={[4, 4, 0]}
                  placeholder="Количество гостей"
                  type="text"
                  borderRadius="0px"
                  border="1px solid #282828"
                  name="count"
                />

                <Input
                  value={phone}
                  onChange={(e) => setPhone(e.target.value)}
                  width={["100%", "100%", "47%"]}
                  height={["50px", "50px", "60px"]}
                  placeholder="+7 999 888 77 55"
                  type="text"
                  borderRadius="0px"
                  border="1px solid #282828"
                  name="phone"
                />
              </Flex>

              <Flex
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                my={[2, 2, 4]}
              >
                <Flex
                  width={["100%", "100%", "47%"]}
                  justifyContent="space-between"
                >
                  <Input
                    value={dateIn}
                    onChange={(e) => setDateIn(e.target.value)}
                    width="45%"
                    height={["50px", "50px", "60px"]}
                    placeholder="Дата заезда"
                    type="date"
                    borderRadius="0px"
                    border="1px solid #282828"
                    my={[4, 4, 0]}
                    name="dateIn"
                  />

                  <Input
                    value={dateOut}
                    onChange={(e) => setDateOut(e.target.value)}
                    width="45%"
                    height={["50px", "50px", "60px"]}
                    placeholder="Дата выезда"
                    type="date"
                    borderRadius="0px"
                    border="1px solid #282828"
                    my={[4, 4, 0]}
                    name="dateOut"
                  />
                </Flex>

                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  width={["100%", "100%", "47%"]}
                  height={["50px", "50px", "60px"]}
                  placeholder="Email"
                  type="email"
                  borderRadius="0px"
                  border="1px solid #282828"
                  name="email"
                />
              </Flex>

              <Flex
                direction={["column", "column", "row"]}
                justifyContent="space-between"
                alignItems="center"
                my={[2, 2, 4]}
              >
                <Flex
                  width={["100%", "100%", "47%"]}
                  justifyContent="space-between"
                >
                  <Checkbox
                    checked={agreement}
                    onChange={(e) => setAgreement(e.target.checked)}
                    colorScheme="green"
                    fontSize={["sm", "md", "lg"]}
                    my={[2, 2, 0]}
                  >
                    Нажимая кнопку «Отправить запрос», вы соглашаетесь с нашей
                    политикой обработки персональных данных
                  </Checkbox>
                </Flex>

                <Button
                  // onClick={() => sendEmailHandler()}
                  isDisabled={
                    !agreement ||
                    !name ||
                    !phone ||
                    !dateIn ||
                    !dateOut ||
                    isLoading
                  }
                  width={["100%", "100%", "47%"]}
                  height={["50px", "50px", "60px"]}
                  borderRadius="0px"
                  colorScheme="teal"
                  type="submit"
                >
                  ОТПРАВИТЬ ЗАПРОС
                </Button>
              </Flex>
            </form>
            {isLoading && (
              <Spinner
                thickness="4px"
                speed="0.65s"
                emptyColor="gray.200"
                color="blue.500"
                size="xl"
                style={{
                  position: "absolute",
                  bottom: isSmallerThan900 ? "20px" : "76px",
                  right: isSmallerThan900 ? "45%" : "210px",
                }}
              />
            )}
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
