import React from "react";

import CountUp from "react-countup";
import { motion } from "framer-motion";

import parkingIcon from "./../../assets/img/parking.svg";
import salatIcon from "./../../assets/img/salat.svg";
import saunaIcon from "./../../assets/img/sauna.svg";
import keyIcon from "./../../assets/img/key.svg";
import wifiIcon from "./../../assets/img/wifi.svg";
import kedrIcon from "../../assets/img/kedr.svg";
import kedr1Icon from "../../assets/img/kedr1.svg";

import { Flex, Image, Text } from "@chakra-ui/react";

const About = () => {
  const dignity = [
    {
      icon: wifiIcon,
      title: "Бесплатный",
      description: "WI-FI",
    },
    {
      icon: keyIcon,
      title: "Заселение",
      description: "24 часа",
    },
    {
      icon: parkingIcon,
      title: "Бесплатная",
      description: "парковка",
    },
    // {
    //   icon: saunaIcon,
    //   title: "Баня",
    //   description: "с купелью",
    // },
    // {
    //   icon: salatIcon,
    //   title: "Бесплатные",
    //   description: "Завтраки",
    // },
  ];

  //Framer motion
  const MotionFlex = motion(Flex);
  const listAnimation = {
    hidden: {
      y: 300,
      opacity: 0,
    },
    visible: (custom: number) => ({
      y: 0,
      opacity: 1,
      transition: {
        delay: custom * 0.15,
        duration: 1,
        type: "just",
      },
    }),
  };
  return (
    <>
      <MotionFlex
        initial="hidden"
        whileInView="visible"
        viewport={{ amount: 0.5, once: true }}
        direction="row"
        w="100%"
        wrap="wrap"
        justifyContent="space-between"
        mt={["50px", "50px", "100px"]}
        color="#282828"
        fontSize={["14px", "14px", "25px"]}
        fontWeight="400"
      >
        {dignity.map((item, index) => (
          <MotionFlex
            variants={listAnimation}
            custom={index}
            key={index}
            direction="column"
            alignItems="center"
            justifyContent="center"
            m="10px"
          >
            <Image
              src={item.icon}
              w={["40px", "40px", "100px"]}
              height={["40px", "40px", "100px"]}
              my="10px"
            />
            <Text>{item.title}</Text>
            <Text>{item.description}</Text>
          </MotionFlex>
        ))}
      </MotionFlex>

      <Flex
        direction={["column", "column", "row"]}
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
        minH="200px"
        mt={["50px", "50px", "100px"]}
        color="#282828"
        fontSize={["14px", "16px", "20px"]}
        fontWeight="400"
        lineHeight="24px"
      >
        <Image src={kedr1Icon} zIndex="hide" position="absolute" left={0} />
        <Text
          w={["100%", "100%", "34%"]}
          mb={[8, 4, 2]}
          fontSize={["26px"]}
          fontWeight="500"
          lineHeight="30px"
        >
          О гостевом доме
        </Text>

        <Flex
          direction="column"
          justifyContent="space-between"
          w={["100%", "100%", "66%"]}
          minH="140px"
        >
          <Text mb={[8, 4, 2]}>
            Гостевой дом распологается в непосредственной близости от авто и ж/д
            вокзалов, торгового центра и районной администрации.
          </Text>

          <Text mb={[8, 4, 2]}>
            На территории гостевого дома имеется парковка.
          </Text>
        </Flex>
      </Flex>

      <Flex
        direction={"row"}
        wrap="wrap"
        justifyContent="space-between"
        alignItems="flex-start"
        w="100%"
        minH="200px"
        mt={["50px", "50px", "100px"]}
        color="#282828"
      >
        <Image src={kedrIcon} zIndex="hide" position="absolute" right={0} />
        <Flex
          direction="column"
          minW={["100%", "32%"]}
          pt="20px"
          borderTop="1px solid #909090"
          textAlign={["center", "center", "left"]}
        >
          <Text
            fontSize={["40px", "40px", "64px"]}
            lineHeight={["56px", "56px", "90px"]}
            fontWeight="400"
          >
            <CountUp
              start={0}
              end={5}
              duration={1}
              enableScrollSpy={true}
              scrollSpyDelay={500}
            />
          </Text>
          <Text
            fontSize={["16px", "16px", "20px"]}
            lineHeight={["24px", "24px", "30px"]}
            fontWeight="300"
          >
            страндартных одноместных номеров
          </Text>
        </Flex>

        <Flex
          direction="column"
          minW={["100%", "32%"]}
          pt="20px"
          borderTop="1px solid #909090"
          textAlign={["center", "center", "left"]}
        >
          <Text
            fontSize={["40px", "40px", "64px"]}
            lineHeight={["56px", "56px", "90px"]}
            fontWeight="400"
          >
            <CountUp
              start={0}
              end={4}
              duration={1}
              enableScrollSpy={true}
              scrollSpyDelay={500}
            />
          </Text>
          <Text
            fontSize={["16px", "16px", "20px"]}
            lineHeight={["24px", "24px", "30px"]}
            fontWeight="300"
          >
            стандарных двухместных номера
          </Text>
        </Flex>

        <Flex
          direction="column"
          minW={["100%", "33%"]}
          pt="20px"
          borderTop="1px solid #909090"
          textAlign={["center", "center", "left"]}
        >
          <Text
            fontSize={["40px", "40px", "64px"]}
            lineHeight={["56px", "56px", "90px"]}
            fontWeight="400"
          >
            <CountUp
              start={0}
              end={2}
              duration={1}
              enableScrollSpy={true}
              scrollSpyDelay={500}
            />
          </Text>
          <Text
            fontSize={["16px", "16px", "20px"]}
            lineHeight={["24px", "24px", "30px"]}
            fontWeight="300"
          >
            номера категории ЛЮКС
          </Text>
        </Flex>
      </Flex>
    </>
  );
};

export default About;
