import React, { useState } from "react";

import { motion } from "framer-motion";

import room1 from "../../assets/img/single0.jpg";
import room2 from "../../assets/img/double0.jpg";
import room3 from "../../assets/img/suite0.jpg";

import { Flex, Image, Text, Button, useDisclosure } from "@chakra-ui/react";
import { ArrowForwardIcon } from "@chakra-ui/icons";
import { RequestModal, RoomsEnum } from "../../components/requestModal/RequestModal";
import { Link } from "react-router-dom";

const Rooms = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const [selectRoom, setSelectRoom] = useState(RoomsEnum.n1);

  // Framer Motion
  const MotionFlex = motion(Flex);
  const whileHover = {
    scale: 1.025,
    transition: { duration: 0.1 },
    cursor: "pointer",
  };

  const rooms = [
    {
      image: room1,
      title: "СТАНДАРТ одноместный",
      description:
        "Просторная комната площадью 16 кв. м с большим окном. В номере имеется телевизор, санузел, телевизор, кондиционер.",
      link: "/single-room",
      value: RoomsEnum.n1,
    },
    {
      image: room2,
      title: "СТАНДАРТ двухместный",
      description:
        "Просторная комната площадью 18 кв. м, с большим окном. В номере имеется телевизор, санузел, телевизор, кондиционер.",
      link: "/double-room",
      value: RoomsEnum.n2,
    },
    {
      image: room3,
      title: "Номер ЛЮКС",
      description:
        "Двухкомнатный номер площадью 32 кв. м. В  номере имеется комфортабельная двухместная кровать, телевизор, санузел,  кондиционер.",
      link: "/suite",
      value: RoomsEnum.nV,
    },
  ];

  return (
    <>
      <Flex
        direction="column"
        w="100%"
        justifyContent="space-between"
        mt={["50px"]}
        color="#282828"
      >
        <Text
          fontSize={["18px", "26px", "44px"]}
          lineHeight={["22px", "36px", "52px"]}
          fontWeight="400"
        >
          Номерной фонд
        </Text>

        <Flex
          direction="row"
          wrap="wrap"
          w="100%"
          justifyContent="space-between"
          mt={["50px"]}
          color="#282828"
        >
          {rooms.map((room, index) => (
            <MotionFlex
              whileHover={whileHover}
              key={index}
              direction={["column"]}
              justifyContent="space-between"
              alignItems="flex-start"
              maxW={["100%", "100%", "360px"]}
              my={4}
            >
              <Image src={room.image} w="100%" />

              <Flex direction="column" mt={["50px"]} minH={["200px"]} w="100%">
                <Text
                  fontSize={["18px", "18px", "26px"]}
                  lineHeight={["22px", "22px", "36px"]}
                  fontWeight="400"
                >
                  {room.title}
                </Text>

                <Text
                  mt={["50px"]}
                  fontSize={["16px", "16px", "20px"]}
                  lineHeight={["24px", "24px", "30px"]}
                  fontWeight="300"
                >
                  {room.description}
                </Text>

                <Link to={room.link}>
                  <Button
                    w="100%"
                    alignSelf="end"
                    colorScheme="teal"
                    variant="ghost"
                    rightIcon={<ArrowForwardIcon />}
                    mt={["50px"]}
                    fontSize={["16px", "16px", "20px"]}
                    lineHeight={["24px", "24px", "30px"]}
                    fontWeight="300"
                  >
                    подробнее
                  </Button>
                </Link>

                <Button
                  onClick={() => {
                    setSelectRoom(room.value);
                    onOpen();
                  }}
                  w="100%"
                  h="60px"
                  mt="20px"
                  colorScheme="teal"
                  fontSize={["26px"]}
                  lineHeight={["36px"]}
                  fontWeight="400"
                  background="#133115"
                  color="#FFF"
                  borderRadius="0px"
                >
                  Забронировать
                </Button>

                <RequestModal
                  isOpen={isOpen}
                  onClose={onClose}
                  value={selectRoom}
                />
              </Flex>
            </MotionFlex>
          ))}
        </Flex>
      </Flex>
    </>
  );
};

export default Rooms;
