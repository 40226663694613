import React from "react";
import styles from "./Contacts.module.scss";

import { YMaps, Map, Placemark } from "@pbe/react-yandex-maps";
import { Button, Flex, Icon, Text } from "@chakra-ui/react";

import { animateScroll as scroll } from "react-scroll";

import { TriangleUpIcon } from "@chakra-ui/icons";

const Contacts = () => {
  const scrollTop = () => {
    scroll.scrollToTop({
      duration: 800,
      delay: 0,
      smooth: "easeInOutQuart",
    });
  };

  return (
    <div className={styles.container}>
      <div className={styles.contacts}>
        <Button
          onClick={() => scrollTop()}
          position="absolute"
          left={["10px", "10px", "50px"]}
          bottom={["550px", "550px", "50px"]}
          zIndex="modal"
        >
          <Icon as={TriangleUpIcon} w={6} h={10} />
        </Button>

        <Flex
          direction="column"
          justifyContent="space-between"
          width={["100%", "100%", "100%", "70%"]}
          alignItems={["center", "center", "center", "flex-start"]}
          textAlign="center"
          my={4}
        >
          <Text fontSize="2xl" fontWeight="medium">
            Гостевой Дом
          </Text>
          <Text>с. Поспелиха, Алтайский край</Text>
        </Flex>

        <Flex
          direction={["column", "column", "column", "row"]}
          justifyContent="space-between"
          width={["100%", "100%", "100%", "70%"]}
          alignItems="center"
          my={4}
        >
          <Flex direction="column" alignItems="center">
            <Text fontSize="2xl" fontWeight="medium" mb={[0, 0, 0, 4]}>
              Контакты
            </Text>

            <a href="mailto:order@pos-hotel.ru?subject=Бронирование">
              <Text>order@pos-hotel.ru</Text>
            </a>
            <Text>+ 7 983 555 10 10</Text>
          </Flex>

          <Flex
            direction="column"
            alignItems={["center", "center", "center", "flex-start"]}
            mt={[2, 2, 0]}
          >
            <Text fontSize="2xl" fontWeight="medium" mb={[0, 0, 0, 4]}>
              Адрес
            </Text>
            <Text>с. Поспелиха</Text>
            <Text>Алтайский край, Вокзальная 11</Text>
          </Flex>
        </Flex>
      </div>

      <div className={styles.map}>
        <YMaps>
          <Map
            className={styles.map_inner}
            defaultState={{
              center: [51.992447, 81.826712],
              zoom: 15,
              controls: ["zoomControl", "fullscreenControl"],
            }}
            modules={["control.ZoomControl", "control.FullscreenControl"]}
          >
            <Placemark defaultGeometry={[51.992447, 81.826712]} />
          </Map>
        </YMaps>
      </div>
    </div>
  );
};

export default Contacts;
