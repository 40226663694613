import * as React from "react";
import * as ReactDOM from "react-dom/client";

import { App } from "./App";

import reportWebVitals from "./reportWebVitals";
import * as serviceWorker from "./serviceWorker";

import { Toaster } from "react-hot-toast";

import { HelmetProvider } from "react-helmet-async";

import { BrowserRouter } from "react-router-dom";

import "./index.scss";

const container = document.getElementById("root");
if (!container) throw new Error("Failed to find the root element");
const root = ReactDOM.createRoot(container);

root.render(
  // <React.StrictMode>
  <BrowserRouter>
    <Toaster />
    <HelmetProvider>
      <App />
    </HelmetProvider>
  </BrowserRouter>
  // </React.StrictMode>
);

serviceWorker.unregister();

reportWebVitals();
