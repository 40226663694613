import React, { FC, ReactNode } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";

import logoImage from "../../assets/img/logo512.png";
import { onlyText } from "../clearText/clearText";

interface ISeo {
  title: string;
  description?: string;
}

export const Meta: FC<ISeo> = ({ title, description }) => {
  const siteName = "Уют и Природа в каждом вдохе! ";
  const titleMerge = (title: string) => `${title} | ${siteName}`;

  const fullPath = window.location.href;

  //   const { asPath } = useRouter();
  //   const currentUrl = `${process.env.APP_URL}${asPath}`;

  return (
    <>
      {description ? (
        <Helmet>
          <title itemProp="headline">{titleMerge(title)}</title>
          <meta
            itemProp="description"
            name="description"
            content={onlyText(description, 152)}
          />
          <link rel="canonical" href={fullPath} />
          <meta property="og:locale" content="en" />
          <meta property="og:title" content={titleMerge(title)} />
          <meta property="og:url" content={fullPath} />
          <meta property="og:image" content={logoImage} />
          <meta property="og:site_name" content={siteName} />
          <meta
            property="og:description"
            content={onlyText(description, 197)}
          />
        </Helmet>
      ) : (
        <meta name="robots" content="noindex, nofollow" />
      )}
    </>
  );
};
