import React, { useState } from "react";
import styles from "./SingleRoom.module.scss";

import { Link } from "react-router-dom";

import { RequestModal, RoomsEnum } from "../../components/requestModal/RequestModal";
import { Meta } from "../../utils/meta/Meta";

import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/navigation";
import "swiper/css/thumbs";

// eslint-disable-next-line
import "swiper/css/bundle";

import SwiperClass from "swiper/types/swiper-class";

// import required modules
import { Controller, FreeMode, Navigation, Thumbs } from "swiper";

import { ArrowBackIcon } from "@chakra-ui/icons";
import kedrIcon from "../../assets/img/kedr.svg";
import kedr1Icon from "../../assets/img/kedr1.svg";
import kedr2Icon from "../../assets/img/kedr2.svg";

import single1 from "../../assets/img/single1.jpg";
import single2 from "../../assets/img/single2.jpg";
import single3 from "../../assets/img/single3.jpg";
import single4 from "../../assets/img/single4.jpg";

import {
  Box,
  Button,
  Flex,
  Icon,
  Image,
  Text,
  useDisclosure,
} from "@chakra-ui/react";

const SingleRoom = () => {
  const [thumbsSwiper, setThumbsSwiper] = useState<SwiperClass | null>(null);

  const [controlledSwiper, setControlledSwiper] = useState<any>(null);

  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Meta
        title="Одноместный номер в Гостевом доме в поселке Поспелиха"
        description="Комната площадью 16 кв. м с большим окном, выходящим в тихий двор."
      />

      <Flex
        w="100%"
        minH="100vh"
        bgGradient="linear(to-r, #ffefba, #ffffff)"
        direction="column"
        alignItems="center"
        justifyContent="center"
        color="#282828"
      >
        <Image src={kedr2Icon} position="absolute" top={"-100px"} right={0} />
        <Image src={kedr1Icon} position="absolute" left={0} top={300} />

        <Flex
          width={["90%", "90%", "90%", "75%", "60%"]}
          minH="650px"
          border="2px solid #282828"
          p={[4, 4, 8]}
          direction="column"
        >
          <Flex
            w="100%"
            justifyContent="space-between"
            alignItems="center"
            fontSize={["14px", "16px", "20px"]}
            lineHeight={["20px", "24px", "30px"]}
            fontWeight="400"
          >
            <Text>Номерной фонд</Text>
            <Link to="/">
              <Button colorScheme="teal">
                <Icon as={ArrowBackIcon} w={4} />
              </Button>
            </Link>
          </Flex>

          <Flex
            direction={["column", "column", "row"]}
            justifyContent="space-between"
            minH="200px"
            mt={4}
          >
            <Flex
              w={["100%", "100%", "50%"]}
              direction="column"
              justifyContent="space-between"
            >
              <Swiper
                spaceBetween={10}
                navigation={true}
                loop={true}
                modules={[FreeMode, Navigation, Thumbs]}
                thumbs={{ swiper: thumbsSwiper }}
                freeMode={true}
                watchSlidesProgress={true}
                centeredSlides={true}
                pagination={{ clickable: true }}
              >
                <SwiperSlide>
                  <Image src={single1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src={single2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src={single3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src={single4} />
                </SwiperSlide>
              </Swiper>

              <Swiper
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Navigation, Thumbs]}
                className={styles.mySwiper5}
              >
                <SwiperSlide>
                  <Image src={single1} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src={single2} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src={single3} />
                </SwiperSlide>
                <SwiperSlide>
                  <Image src={single4} />
                </SwiperSlide>
              </Swiper>
            </Flex>

            <Flex
              w={["100%", "100%", "50%"]}
              ml={[0, 4, 8]}
              mt={[4, 4, 0]}
              direction="column"
              justifyContent="space-between"
              alignItems="flex-start"
              fontSize={["14px", "16px", "20px"]}
              lineHeight={["20px", "24px", "30px"]}
              fontWeight="400"
            >
              <Text
                fontSize={["18px", "18px", "26px"]}
                lineHeight={["22px", "22px", "36px"]}
              >
                СТАНДАРТ одноместный
              </Text>

              <Text>Комната площадью 16 кв. м.</Text>

              <Text>В номере:</Text>

              <Box ml={[4]}>
                <ul>
                  <li>односпальная кровать</li>
                  <li>прикроватная тумба</li>
                  <li>письменный стол, стул</li>
                  <li>платяной шкаф</li>
                  <li>санузел</li>
                  <li>телевизор</li>
                  <li>кодиционер</li>
                </ul>
              </Box>

              <Text
                color="#133115"
                fontSize={["26px", "26px", "40px"]}
                lineHeight={["36px", "36px", "56px"]}
              >
                1800₽ в сутки
              </Text>

              <Button
                onClick={() => {
                  onOpen();
                }}
                w="100%"
                h="60px"
                mt="20px"
                colorScheme="teal"
                fontSize={["26px"]}
                lineHeight={["36px"]}
                fontWeight="400"
                background="#133115"
                color="#FFF"
                borderRadius="0px"
              >
                Забронировать
              </Button>

              <RequestModal isOpen={isOpen} onClose={onClose} value={RoomsEnum.n1} />
            </Flex>
          </Flex>
        </Flex>
        <Image src={kedrIcon} position="absolute" right={0} bottom={20} />
      </Flex>
    </>
  );
};

export default SingleRoom;
