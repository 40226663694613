import * as React from "react";
import { ChakraProvider, Flex } from "@chakra-ui/react";
import Home from "./pages/Home";

import { Routes, Route, Navigate } from "react-router-dom";

import SingleRoom from "./pages/singleRoom/SingleRoom";
import DoubleRoom from "./pages/doubleRoom/DoubleRoom";
import Suite from "./pages/suite/Suite";

export const App = () => (
  <ChakraProvider>
    <Flex direction="column" justifyContent="center" alignItems="center">
      <Routes>
        <Route path="/" element={<Home />}>
          <Route index element={<Home />} />
        </Route>
        <Route path="single-room" element={<SingleRoom />} />
        <Route path="double-room" element={<DoubleRoom />} />
        <Route path="suite" element={<Suite />} />
      </Routes>
    </Flex>
  </ChakraProvider>
);
