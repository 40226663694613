import { Flex, Image, Text } from "@chakra-ui/react";
import React from "react";

import kedr2Icon from "../../assets/img/kedr2.svg";

const Advantages = () => {
  return (
    <>
      <Flex
        direction="column"
        w="100%"
        minH="80vh"
        justifyContent="space-around"
        alignItems="flex-start"
        mt={["50px", "50px", "100px"]}
        color="#282828"
        fontWeight="400"
      >
        <Flex direction={["column", "column", "row"]} w="100%">
          <Text fontSize={["26px"]} lineHeight="36px" w="35%">
            Для Вас
          </Text>

          <Flex w={["100%", "100%", "60%"]}>
            <Text
              fontSize={["30px", "30px", "40px"]}
              lineHeight={["40px", "40px", "52px"]}
            >
              При обустройстве дома, мы старались, для наших гостей, обеспечить
              максимально удобный и недорогой отдых
            </Text>
          </Flex>
        </Flex>

        <Flex
          wrap="wrap"
          direction="row"
          justifyContent="space-between"
          w="100%"
          mt={["30px", "30px", "100px"]}
          fontSize={["16px", "16px", "20px"]}
          lineHeight={["24px", "24px", "30px"]}
          fontWeight="400"
        >
          <Flex
            direction={"column"}
            justifyContent="space-between"
            w={["100%", "100%", "30%"]}
            minH={["150px", "170px", "200px"]}
            my={[2, 2, 0]}
          >
            <Text>
              На территории всего гостевого дома для Вашего удобства имеется
              быстрый интернет с хорошим покрытием.
            </Text>
            <br />
            <Text mb={8}>
              Гостям нашего дома доступена охранаяемая бесплатная сотянка на 10
              парковочных мест.
            </Text>
          </Flex>

          <Flex
            direction={"column"}
            justifyContent="space-between"
            w={["100%", "100%", "30%"]}
            minH={["150px", "170px", "200px"]}
            my={[2, 2, 0]}
          >
            <Text>
              В нашем доме вы найдете подходящие номера в зависимости от ваших
              предпочтений и бюджета. Принимаем к оплате карты.
            </Text>
            <br />
            <Text>
              Добраться до нас можно на поезде или автобусе до станции Поспелиха
              или на машине.
            </Text>
          </Flex>

          <Flex
            direction={"column"}
            justifyContent="space-between"
            w={["100%", "100%", "30%"]}
            minH={["150px", "170px", "200px"]}
            my={[2, 2, 0]}
          >
            <Text>
              Для постоянных клиентов разработана система скидок на все услуги
              предоставляемые комплексом.
            </Text>
            <br />
            <Text>
              Продукты для нашего ресторана мы выращиваем в собственном садово -
              огодном хозяйстве.
            </Text>
          </Flex>
          <Image src={kedr2Icon} zIndex="hide" position="absolute" right={0} />
        </Flex>
      </Flex>
    </>
  );
};

export default Advantages;
